<template>
  <div :class="$style.page">
    <div :class="$style.wrapper">
      <el-button size="big" type="primary" @click="submitForm">
        Cоздать
      </el-button>
    </div>
    <el-form
      :class="$style.form"
      :model="order"
      ref="form"
      :rules="rules"
      label-position="right"
      label-width="200px"
    >
      <el-form-item label="Статус заказа" prop="status">
        <el-select
          v-model="order.status"
          :class="$style.select"
          placeholder="Выберите статус заказа"
        >
          <el-option
            v-for="status in Object.keys($options.ADDEVENT_ORDER_STATUSES)"
            :key="status"
            :label="$options.ADDEVENT_ORDER_STATUSES_DISPLAY[status]"
            :value="status"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Тип доставки для получения" prop="deliveryType">
        <el-select
          v-model="order.deliveryType"
          :class="$style.select"
          placeholder="Выберите тип доставки"
        >
          <el-option
            v-for="item in $options.DELIVERY_TYPES_LIST"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Тип доставки для возврата" prop="deliveryTypeFrom">
        <el-select
          v-model="order.deliveryTypeFrom"
          :class="$style.select"
          placeholder="Выберите тип доставки"
        >
          <el-option
            v-for="item in $options.DELIVERY_TYPES_LIST"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Способ оплаты" prop="paymentType">
        <el-select
          :class="$style.select"
          v-model="order.paymentType"
          placeholder="Выберите способ оплаты"
        >
          <el-option
            v-for="item in $options.PAYMENTS_TYPES_LIST"
            :key="item.id"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Статус оплаты" prop="paymentStatus">
        <el-select
          v-model="order.paymentStatus"
          :class="$style.select"
          placeholder="Выберите статус оплаты"
        >
          <el-option
            v-for="status in Object.keys($options.ADDEVENT_PAYMENT_STATUSES)"
            :key="status"
            :label="$options.ADDEVENT_PAYMENT_STATUSES_DISPLAY[status]"
            :value="status"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Кол-во суток аренды по обычной цене" prop="baseDays">
        <el-input-number v-model="order.baseDays" :min="1" />
      </el-form-item>
      <el-form-item label="Начало аренды" prop="startAt">
        <AdwCalendar
          id="orderDatePicker"
          :value="order.startAt"
          :disabled-dates="disabledDates"
          minimum-view="day"
          maximum-view="day"
          placeholder="Дата начала аренды"
          is-hide-icon
          @selected="handleSelectStartDate"
        />
      </el-form-item>
      <el-form-item label="Окончание аренды" prop="finishAt">
        <AdwCalendar
          id="orderDatePicker"
          :value="order.finishAt"
          :disabled-dates="disabledDates"
          :error="errors.finishAt"
          minimum-view="day"
          maximum-view="day"
          placeholder="Дата окончания аренды"
          is-hide-icon
          @selected="handleSelectEndDate"
        />
      </el-form-item>
      <div v-if="order.deliveryType === $options.DELIVERY_TYPE.DELIVERY">
        <el-form-item label="Адрес куда доставить" prop="deliveryAddressTo">
          <el-input
            v-model="order.deliveryAddressTo"
            placeholder="Введите адрес куда доставить"
          ></el-input>
        </el-form-item>
      </div>
      <div v-if="order.deliveryTypeFrom === $options.DELIVERY_TYPE.DELIVERY">
        <template v-if="order.deliveryType === $options.DELIVERY_TYPE.DELIVERY">
          <el-form-item :class="$style.sameDeliveryWrapper">
            <el-checkbox
              v-model="isUsedSameAddress"
              placeholder="Введите адрес куда доставить"
            />
            <span :class="$style.sameDeliveryText"
              >Использовать тот же адрес, чтобы забрать продукты</span
            >
          </el-form-item>
        </template>
        <el-form-item
          v-if="!isUsedSameAddress"
          label="Адрес от куда забрать"
          prop="deliveryAddressFrom"
        >
          <el-input
            v-model="order.deliveryAddressFrom"
            placeholder="Введите адрес от куда забрать "
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item label="Цена доставки">
        <el-input v-model.number="order.deliveryPrice"></el-input>
      </el-form-item>
      <el-form-item label="Имя получателя" prop="client.name">
        <el-input v-model="order.client.name"></el-input>
      </el-form-item>
      <el-form-item label="Телефон получателя" prop="client.phone">
        <el-input v-model="order.client.phone"></el-input>
      </el-form-item>
      <el-form-item label="Почта получателя" prop="client.email">
        <el-input v-model="order.client.email"></el-input>
      </el-form-item>
      <el-form-item label="Комментарий" prop="comment">
        <el-input
          v-model="order.comment"
          type="textarea"
          resize="none"
        ></el-input>
      </el-form-item>
      <h3>Состав заказа</h3>
      <p :class="$style.relatedProductInfoText">
        При добавлении основного товара - его сопутствующие товары попадают в
        селектор для выбора сопутствующих товар. Там вы можете добавить все
        интересующие вас сопутствующие товары
      </p>
      <el-form-item label="Выберите новый товар">
        <div :class="$style.search">
          <Autocomplete
            :search="productSearch"
            placeholder="Поиск товара"
            :valueNames="['name']"
            @querySearch="getProductsList"
            @input="productSearch = $event"
            @loadMore="loadMoreProducts"
            @selectItem="handleSelectProduct"
          />
        </div>
      </el-form-item>
      <el-table v-if="order.products?.length" :data="order.products" stripe>
        <el-table-column label="Изображение">
          <template slot-scope="scope">
            <img
              v-if="scope.row.images"
              width="auto"
              height="70"
              :src="setupImagesListData(scope.row?.images).original"
              :alt="setupImagesListData(scope.row?.images).caption"
            />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="Название"> </el-table-column>
        <el-table-column label="Количество">
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.quantity"
              controls-position="right"
              :min="1"
              :max="scope.row?.stocks"
            />
          </template>
        </el-table-column>
        <el-table-column
          :label="`Цена за ${order?.baseDays} суток`"
          width="120"
        >
          <template slot-scope="scope">
            {{ scope.row?.basicPricePerDay * order?.baseDays }}
          </template>
        </el-table-column>
        <el-table-column label="Цена за последующие сутки" width="150">
          <template slot-scope="scope">
            {{ scope.row?.additionalPricePerDay }}
          </template>
        </el-table-column>
        <el-table-column label="Общая сумма" width="100">
          <template slot-scope="scope">
            {{ getProductTotalPrice(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot-scope="scope">
            <ActionButtons
              :viewLink="
                getRoute({
                  route: $options.ADDEVENT_ADMIN_ROUTES.CATALOG.PRODUCTS.UPDATE,
                  params: { id: scope.row?.id },
                })
              "
              @delete="handleRemoveProduct(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <div v-if="relatedProducts.length" :class="$style.relatedProducts">
        <div :class="$style.selectWrapper">
          <h3>Сопутствующие товары</h3>

          <el-select
            :value="addedRelatedProducts"
            filterable
            placeholder="Выберите сопутствующие товары"
            clearable
            multiple
            value-key="id"
            :value-on-clear="[]"
            :class="$style.select"
            @change="handleAddRelatedProducts"
          >
            <el-option
              v-for="item in relatedProducts"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </div>
        <el-table
          v-if="addedRelatedProducts?.length"
          :data="addedRelatedProducts"
          stripe
        >
          <el-table-column label="Изображение">
            <template slot-scope="scope">
              <img
                width="auto"
                height="70"
                :src="setupImagesListData(scope.row?.images).original"
                :alt="setupImagesListData(scope.row?.images).caption"
                @error="setDefaultImage"
              />
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Название"> </el-table-column>
          <el-table-column label="Количество">
            <template slot-scope="scope">
              <el-input-number
                v-model="scope.row.quantity"
                controls-position="right"
                :min="1"
                :max="scope.row?.stocks?.[0]?.stock"
              />
            </template>
          </el-table-column>
          <el-table-column label="Цена" width="120">
            <template slot-scope="scope">
              {{ scope.row?.price }}
            </template>
          </el-table-column>
          <el-table-column label="Общая сумма" width="100">
            <template slot-scope="scope">
              {{ scope.row.price * (scope.row.quantity ?? 1) }}
            </template>
          </el-table-column>
          <el-table-column width="100">
            <template slot-scope="scope">
              <ActionButtons
                :viewLink="
                  getRoute({
                    route:
                      $options.ADDWINE_ADMIN_ROUTES.CATALOG.PRODUCTS.PRODUCT,
                    params: { id: scope.row?.id },
                  })
                "
                @delete="handleRemoveRelatedProduct(scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <h1 v-if="totalPrice" :class="$style.price">
        Сумма заказа: {{ totalPrice + relatedProductsTotalPrice }} ₽
      </h1>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'

import { getStringEnding } from '@/helpers/index'
import regexp from '@/helpers/regexp'

import notifications from '@/mixins/notifications'
import images from '@/mixins/images'

import { DELIVERY_TYPE, DELIVERY_TYPES_LIST } from '@/constants/deliveryType'
import { PAYMENTS_TYPES_LIST } from '@/constants/paymentTypes'
import {
  ADDEVENT_ORDER_STATUSES,
  ADDEVENT_ORDER_STATUSES_DISPLAY,
} from '@/constants/orderStatuses'

import {
  ADDEVENT_PAYMENT_STATUSES,
  ADDEVENT_PAYMENT_STATUSES_DISPLAY,
} from '@/constants/paymentStatuses'

import {
  getRoute,
  ADDEVENT_ADMIN_ROUTES,
  ADDWINE_ADMIN_ROUTES,
} from '@/constants/routing'
import { DAY_IN_MS } from '@/constants/common'

import Autocomplete from '@/components/atoms/Autocomplete.vue'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'

export default {
  DELIVERY_TYPE,
  DELIVERY_TYPES_LIST,
  PAYMENTS_TYPES_LIST,
  ADDEVENT_ORDER_STATUSES,
  ADDEVENT_ORDER_STATUSES_DISPLAY,
  ADDEVENT_ADMIN_ROUTES,
  ADDEVENT_PAYMENT_STATUSES,
  ADDEVENT_PAYMENT_STATUSES_DISPLAY,
  ADDWINE_ADMIN_ROUTES,
  components: {
    Autocomplete,
    ActionButtons,
  },
  mixins: [notifications, images],
  data() {
    return {
      relatedProducts: [],
      addedRelatedProducts: [],
      minOrderDays: 0,
      isUsedSameAddress: false,
      setSearchItems: null,
      productSearch: '',
      searchProducts: [],
      page: 1,
      limit: 20,
      productsCount: null,
      order: {
        status: '',
        paymentType: '',
        paymentStatus: '',
        deliveryType: '',
        deliveryTypeFrom: '',
        client: {},
        deliveryAddressTo: '',
        deliveryAddressFrom: null,
        products: [],
        startAt: null,
        finishAt: null,
        deliveryPrice: 0,
        baseDays: 3,
        comment: '',
      },
      errors: {
        finishAt: '',
      },
      rules: {
        deliveryType: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        deliveryTypeFrom: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        status: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        paymentStatus: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        paymentType: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        baseDays: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        deliveryAddressTo: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        deliveryAddressFrom: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        client: {
          name: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
          phone: [
            {
              pattern: regexp.phone,
              message: 'Пожалуйста, введите корретный телефон',
              trigger: 'change',
            },
          ],
          email: [
            {
              pattern: regexp.email,
              message: 'Пожалуйста, введите корректную почту',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  async created() {
    await this.getSettings()
  },
  computed: {
    disabledDates() {
      const currentDay = new Date(Date.now() - DAY_IN_MS)

      return { to: currentDay }
    },
    relatedProductsTotalPrice() {
      if (!this.addedRelatedProducts?.length) return 0

      return this.addedRelatedProducts.reduce((total, { price, quantity }) => {
        return total + quantity * price
      }, 0)
    },
    totalPrice() {
      if (!this.order?.products?.length) return 0

      const startRentDate = new Date(this.order?.startAt).getTime()
      const finishRentDate = new Date(this.order?.finishAt).getTime()

      const rentDays = finishRentDate - startRentDate + DAY_IN_MS
      const moreRentDaysInMs = rentDays - this.order?.baseDays * DAY_IN_MS

      if (moreRentDaysInMs <= 0) {
        return this.order?.products?.reduce(
          (total, { basicPricePerDay, quantity }) =>
            (total += basicPricePerDay * this.order.baseDays * quantity),
          0,
        )
      }

      const moreRentDaysInDay = moreRentDaysInMs / DAY_IN_MS

      return this.order?.products?.reduce(
        (total, { additionalPricePerDay, basicPricePerDay, quantity }) =>
          (total +=
            (basicPricePerDay * this.order?.baseDays +
              additionalPricePerDay * moreRentDaysInDay) *
            quantity),
        0,
      )
    },
  },
  methods: {
    async getSettings() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AddeventServiceCore.SettingsActions.getList()

      loading.close()

      if (error) {
        this.showNotification('Ошибка получения настроек', 'error')
        return
      }

      this.minOrderDays = value.minOrderDays
      this.order.baseDays = value.minOrderDays
    },

    async setProducts(setItems, getOldItems = false) {
      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList({
          search: this.productSearch,
          page: this.page,
          limit: this.limit,
          reservationStart: this.order.startAt,
          reservationEnd: this.order.finishAt,
          inStock: true,
        })

      if (error) return

      if (!value?.data?.length) return

      value?.data?.push({ isLoading: true })
      this.productsCount = value.products?.meta?.count

      if (getOldItems) {
        this.searchProducts.pop()
        this.searchProducts = [...this.searchProducts, ...value?.data]
      } else {
        this.searchProducts = [...value?.data]
      }

      setItems(this.searchProducts)
    },
    async getProductsList({ setSearchItems }) {
      if (!this.order.startAt)
        return this.showNotification('Выберите дату начала аренды', 'warning')

      if (!this.order.finishAt)
        return this.showNotification(
          'Выберите дату окончания аренды',
          'warning',
        )

      this.setSearchItems = setSearchItems
      this.page = 1

      this.setProducts(this.setSearchItems)
    },
    async loadMoreProducts() {
      if (this.limit * this.page < this.productsCount) {
        this.page++

        this.setProducts(this.setSearchItems, true)
      }
    },
    async recalculateProductsAvailability() {
      if (!this.order.startAt || !this.order.finishAt) {
        return
      }
      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList({
          reservationStart: this.order.startAt,
          reservationEnd: this.order.finishAt,
          productIds: this.order.products?.map((e) => e?.id),
        })

      if (error) return

      if (!value?.data?.length) return
      const stocksMap = value?.data?.reduce((acc, { id, stocks }) => {
        acc[id] = stocks
        return acc
      }, {})

      const productsWithNotEnoughStocks = []

      this.order.products =
        this.order.products?.map((e) => {
          if (e.quantity > stocksMap[e?.productId])
            productsWithNotEnoughStocks.push(e?.name)

          return {
            ...e,
            stocks: stocksMap[e?.productId],
          }
        }) ?? []

      if (productsWithNotEnoughStocks?.length)
        this.showNotification(
          `Данных товаров недостаточно в наличии на указанные даты: ${productsWithNotEnoughStocks?.join(
            ',',
          )}`,
          'error',
        )
    },
    async handleSelectStartDate(date) {
      const startRentDate = new Date(date).getTime()
      const finishRentDate = new Date(this.order?.finishAt).getTime()

      if (this.order?.finishAt && startRentDate > finishRentDate) {
        this.showNotification(
          `Дата начала не может быть позже, чем дата окончания `,
          'error',
        )
        this.order.startAt = null

        return
      }

      const startRentDateWithBasicDays =
        startRentDate + this.minOrderDays * DAY_IN_MS - DAY_IN_MS

      const daysEndings = ['день', 'дня', 'дней']

      if (this.order?.finishAt && startRentDateWithBasicDays > finishRentDate) {
        this.showNotification(
          `К сожалению, выбранная дата не доступна, т.к. минимальный срок аренды: ${
            this.minOrderDays
          } ${this.getStringEnding(this.minOrderDays, daysEndings)} `,
          'error',
        )

        this.order.startAt = null
        return
      }

      this.order.startAt = date
      await this.recalculateProductsAvailability()
    },
    async handleSelectEndDate(date) {
      const startRentDate = new Date(this.order?.startAt).getTime()
      const finishRentDate = new Date(date).getTime()

      const daysEndings = ['день', 'дня', 'дней']

      if (startRentDate > finishRentDate) {
        this.showNotification(
          `Дата начало не может быть позже, чем дата окончания `,
          'error',
        )
        this.order.finishAt = null

        return
      }

      const startRentDateWithBasicDays =
        startRentDate + this.minOrderDays * DAY_IN_MS - DAY_IN_MS

      if (startRentDateWithBasicDays > finishRentDate) {
        this.showNotification(
          `К сожалению, выбранная дата не доступна, т.к. минимальный срок аренды: ${
            this.minOrderDays
          } ${this.getStringEnding(this.minOrderDays, daysEndings)} `,
          'error',
        )

        this.order.finishAt = null
        return
      }

      this.order.finishAt = date
      this.resetErrors()
      await this.recalculateProductsAvailability()
    },
    async handleSelectProduct(product) {
      const isAddedProductsHasProduct = this.order?.products?.some(
        ({ id }) => product.id === id,
      )

      if (!product?.stocks) {
        this.showNotification('К сожалению, товара нет в наличии', 'error')
        return
      }

      if (isAddedProductsHasProduct) {
        this.showNotification('Товар уже был добавлен', 'error')
        return
      }

      this.order?.products?.push({ ...product, quantity: 1 })

      await this.getRelatedProducts()
    },
    handleChangeProductQuantity(product) {
      const productForChange = this.order?.products?.find(
        ({ id }) => product.id === id,
      )

      productForChange.quantity = product.quantity
    },
    async handleRemoveProduct(product) {
      const isConfirm = confirm(
        `Вы точно хотите удалить продукт: ${product.name}`,
      )

      if (!isConfirm) return

      this.order.products = this.order.products.filter(
        ({ id }) => product.id !== id,
      )

      this.relatedProducts = this.addedRelatedProducts = []

      await this.getRelatedProducts()
    },
    getStringEnding(number, endsList) {
      return getStringEnding(number, endsList)
    },
    getRoute(route, params) {
      return getRoute(route, params)
    },
    getProductTotalPrice(product) {
      const startRentDate = new Date(this.order?.startAt).getTime()
      const finishRentDate = new Date(this.order?.finishAt).getTime()

      const rentDays = finishRentDate - startRentDate + DAY_IN_MS
      const moreRentDaysInMs = rentDays - this.order?.baseDays * DAY_IN_MS

      const totalBasicPrice =
        product.basicPricePerDay * this.order.baseDays * product.quantity

      if (moreRentDaysInMs <= 0) {
        return totalBasicPrice
      }

      const moreRentDaysInDay = moreRentDaysInMs / DAY_IN_MS

      const totalAdditionalPrice =
        product.additionalPricePerDay * moreRentDaysInDay * product.quantity

      return totalAdditionalPrice + totalBasicPrice
    },
    validateForm() {
      const startRentDate = new Date(this.order?.startAt).getTime()
      const finishRentDate = new Date(this.order?.finishAt).getTime()

      const daysEndings = ['день', 'дня', 'дней']

      if (!startRentDate) {
        this.showNotification('Введите корректную дату начала', 'error')
        this.errors.startAt = 'error'
        return false
      }

      if (!finishRentDate) {
        this.showNotification('Введите корректную дату окончания', 'error')
        this.errors.finishAt = 'error'
        return false
      }

      if (startRentDate > finishRentDate) {
        this.showNotification(
          'Дата окончания не может раньше, чем дата начала',
          'error',
        )
        this.errors.finishAt = 'error'
        return false
      }

      const startRentDateWithBasicDays =
        startRentDate + this.minOrderDays * DAY_IN_MS - DAY_IN_MS

      if (startRentDateWithBasicDays > finishRentDate) {
        this.showNotification(
          `К сожалению, выбранная дата окончания аренды не корректна, т.к. минимальный срок аренды: ${
            this.minOrderDays
          } ${this.getStringEnding(this.minOrderDays, daysEndings)} `,
          'error',
        )

        this.errors.finishAt = 'error'
        return false
      }

      if (!this.order.products.length) {
        this.showNotification('В заказе нет ни одного товара', 'error')
        return false
      }

      if (
        this.order.products?.some(
          (e) => e?.stocks >= 0 && e?.stocks < e?.quantity,
        )
      ) {
        this.showNotification(
          'Количество товара не может быть больше чем на складе',
          'error',
        )
        return false
      }
      return true
    },
    resetErrors() {
      this.errors.finishAt = ''
    },
    async submitForm() {
      this.resetErrors()
      if (!this.validateForm()) return

      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        const loading = this.$loading({
          lock: true,
        })

        const convertedProducts = this.order?.products?.map(
          ({ id, quantity }) => ({
            id,
            quantity,
          }),
        )

        const convertedRelatedProducts = this.addedRelatedProducts?.map(
          ({ id, quantity }) => ({
            id,
            quantity,
            isAdditional: true,
          }),
        )

        const order = {
          status: this.order?.status,
          baseDays: this.order?.baseDays,
          startAt: this.order?.startAt,
          finishAt: this.order?.finishAt,
          comment: this.order?.comment,
          paymentStatus: this.order?.paymentStatus,
          paymentType: this.order?.paymentType,
          price: this.totalPrice + this.relatedProductsTotalPrice,
          products: [...convertedProducts, ...convertedRelatedProducts],
          client: this.order?.client,
          deliveryPrice: this.order?.deliveryPrice,
        }

        if (this.order?.deliveryType === DELIVERY_TYPE.DELIVERY) {
          order.deliveryType = DELIVERY_TYPE.DELIVERY
          order.deliveryAddressTo = this.order?.deliveryAddressTo
        }

        if (this.order?.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
          order.deliveryType = DELIVERY_TYPE.SELFDELIVERY
          order.deliveryAddressTo = null
        }

        if (this.order?.deliveryTypeFrom === DELIVERY_TYPE.DELIVERY) {
          order.deliveryTypeFrom = DELIVERY_TYPE.DELIVERY
          order.deliveryAddressFrom = this.isUsedSameAddress
            ? this.order.deliveryAddressTo
            : this.order.deliveryAddressFrom
        }

        if (this.order?.deliveryTypeFrom === DELIVERY_TYPE.SELFDELIVERY) {
          order.deliveryTypeFrom = DELIVERY_TYPE.SELFDELIVERY
          order.deliveryAddressFrom = null
        }

        const { error } =
          await delivery.AddeventServiceCore.OrdersActions.create(order)

        loading.close()

        if (error) {
          this.showNotification('Ошибка создания заказа', 'error')
          return
        }

        this.showNotification('Заказ успешно создан', 'success')
        this.$router.push(ADDEVENT_ADMIN_ROUTES.ORDERS.LIST)
      })
    },
    async getRelatedProducts() {
      const productIds = this.order?.products?.map(({ id }) => id)

      const { value, error } =
        await delivery.AddeventServiceCore.ProductsActions.getList({
          limit: 100,
          page: 1,
          productIds: productIds,
          isWithRelatedProducts: true,
        })

      if (error) return

      this.relatedProducts = value.data
        ?.flatMap((item) => item.relatedProducts)
        ?.filter((item) => item)
    },
    handleRemoveRelatedProduct(product) {
      const isConfirm = confirm(
        `Вы точно хотите удалить продукт: ${product.name}`,
      )

      if (!isConfirm) return

      this.addedRelatedProducts = this.addedRelatedProducts?.filter(
        ({ id }) => product.id !== id,
      )
    },
    handleAddRelatedProducts(products) {
      products = products.map((item) => ({
        ...item,
        quantity: 1,
      }))

      this.addedRelatedProducts = products
    },
  },
}
</script>

<style lang="scss" module>
.page {
  padding: 1rem 2rem 1rem 1rem;
  .wrapper {
    @include stickyWrapper;
  }

  .select {
    width: 100%;
  }

  .form {
    & > div > label {
      text-align: left;
    }

    .sameDeliveryWrapper {
      .sameDeliveryText {
        margin-left: 1rem;
      }
    }

    .price {
      margin-top: 1rem;
    }

    .relatedProductInfoText {
      margin: 1rem 0;
      color: $warning;
    }

    .relatedProducts {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;

      .selectWrapper {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
      }

      .select {
        width: 50%;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
